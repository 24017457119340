import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/business/modriver.svg"

import fastDelivery from "../../static/images/business/fast-delivery.svg"
import money from "../../static/images/business/money.svg"
import trackOrder from "../../static/images/business/lpg-tracking.svg"
import { DRIVER_SIGN_UP_FORM_URL, t } from "../utils/constant"
import { withTrans } from "../i18n/withTrans"

const DrivePage = ({ t: trans, intl }) => (
  <Layout>
    <SEO title={t({ intl: trans, id: "seoTitleIndex" })} />

    <div className={"page-header home drive-image"} style={{ padding: 0 }}>
      <div className="top-content">
        <h1>
          {t({ intl: trans, id: "headerEarningMore1" })} <br />{" "}
          {t({ intl: trans, id: "headerEarningMore2" })}
        </h1>
      </div>
    </div>
    <div className={"call-to-action"}>
      <div className={"container"}>
        <div className={"call-to-action__content"}>
          <h2>{t({ intl: trans, id: "headerCourierPartner" })}</h2>
          <p>{t({ intl: trans, id: "textCourierPartner" })}</p>
        </div>

        <div className={"button"}>
          <a href={DRIVER_SIGN_UP_FORM_URL} target={"_blank"}>
            {t({ intl: trans, id: "textSignUp" })}
          </a>
        </div>
      </div>
    </div>
    <div className={"container"}>
      <div className={"features"}>
        <div className={"feature__item"}>
          <div className={"column"}>
            <div className={"col-12 feature__center"}>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerWhatWillYouBeDoing" })}</h2>
              </div>
            </div>
            <div className={"col-12"}>
              <div className={"feature__content offerings"}>
                <p style={{ fontSize: 25, lineHeight: "35px" }}>
                  <p>{t({ intl: trans, id: "textWhatWillYouBeDoing" })}</p>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={"feature__item"}>
          <div className={"column"}>
            <div className={"col-12 feature__center"}>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerWhatWeProvide" })}</h2>
              </div>
            </div>
            <div className={"col-12"}>
              <div className={"feature__content offerings"}>
                <p>{t({ intl: trans, id: "textWhatWeProvide" })}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-4 first"}>
              <div className={"thumbnail"}>
                <img
                  alt={t({ intl: trans, id: "headerWorkOnOwnTime" })}
                  src={fastDelivery}
                />
              </div>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerWorkOnOwnTime" })}</h2>
                <p>{t({ intl: trans, id: "textWorkOnOwnTime" })}</p>
              </div>
            </div>

            <div className={"col-4"}>
              <div className={"thumbnail"}>
                <img
                  alt={t({ intl: trans, id: "headerDelivery" })}
                  src={trackOrder}
                />
              </div>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerDelivery" })}</h2>
                <p>{t({ intl: trans, id: "textDelivery" })}</p>
              </div>
            </div>
            <div className={"col-4"}>
              <div className={"thumbnail"}>
                <img
                  alt={t({ intl: trans, id: "headerGetPaid" })}
                  src={money}
                />
              </div>
              <div className={"feature__content"}>
                <h2>{t({ intl: trans, id: "headerGetPaid" })}</h2>
                <p>{t({ intl: trans, id: "textGetPaid" })}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={"call-to-action cta-bottom"}>
      <div className={"container"}>
        <div className={"call-to-action__content"}>
          <h2>
            {t({ intl: trans, id: "headerStartMakingExtraMoney1" })} <br />
            {t({ intl: trans, id: "headerStartMakingExtraMoney2" })}
          </h2>
          <p>{t({ intl: trans, id: "textStartMakingExtraMoney" })}</p>
        </div>

        <div className={"button"}>
          <a href={DRIVER_SIGN_UP_FORM_URL} target={"_blank"}>
            {t({ intl: trans, id: "textSignUp" })}
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default withTrans(DrivePage)
